import styled from 'styled-components';
import { Button } from '../Buttons/Buttons.dump';
import { WIDTH_BREAKPOINT_MD } from '@virgilsecurity/virgil-ui/src/lib/constants';

export const HeaderWrapper = styled.div`
	left: 0;
	position: absolute;
	right: 0;
`;

export const HeaderContent = styled.header`
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
`;

export const HeaderNavWrapper = styled.div`
	display: none;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		display: flex;
	}
`;

export const CustomizeButton = styled(Button)`
	border: 1px solid #d32f2f;
	border-radius: 20px;
	color: #ffffff;
	display: none;
	margin-left: auto;
	padding: 8px 20px;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		display: flex;
	}

	&:hover {
		background-color: #da322c;
	}

	&:active {
		background-color: rgb(208, 64, 53);
	}
`;
