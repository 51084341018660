import React from 'react';
import { LayoutContent } from './Layout.dump';
import Header from 'src/components/Header/Header';
import '../styles/style.css';

export interface LayoutProps extends React.HTMLAttributes<HTMLElement> {
	background: string;
}

const Layout: React.FC<LayoutProps> = ({ children, background, ...props }) => {
	return (
		<LayoutContent background={background} {...props}>
			<Header />
			{children}
		</LayoutContent>
	);
};

export default Layout;
