import React from 'react';
import Portal from '../Portal';
import { useState } from 'react';
import {
	MobileNavWrapper,
	MobileNavOverlay,
	MobileNavContainer,
	OpenButton,
	CloseButton,
	MobileNavContentWrapper,
	CustomizeButtonContainer,
	CustomizeButton,
} from './Nav.dump';
import { ReactComponent as BurgerWhite } from 'icons/icon-menu-white.svg';
import { ReactComponent as CloseIcon } from 'icons/icon-close-red.svg';
import Navigation from './Nav';

const MobileNav: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
	const [isOpen, setOpen] = useState(false);

	return (
		<MobileNavWrapper {...props}>
			<OpenButton onClick={() => setOpen(!isOpen)}>
				<BurgerWhite />
			</OpenButton>
			<Portal>
				<MobileNavOverlay isOpen={isOpen} />
				<MobileNavContainer isOpen={isOpen}>
					<MobileNavContentWrapper>
						<CloseButton onClick={() => setOpen(!isOpen)}>
							<CloseIcon />
						</CloseButton>
						<Navigation setModal={setOpen} />
						<CustomizeButtonContainer>
							<CustomizeButton onClick={() => setOpen(!isOpen)} href="#customize">
								Customize
							</CustomizeButton>
						</CustomizeButtonContainer>
					</MobileNavContentWrapper>
				</MobileNavContainer>
			</Portal>
		</MobileNavWrapper>
	);
};

export default MobileNav;
