import React from 'react';
import { Nav, NavLink } from './Nav.dump';
import { headerNavigationTexts } from 'content/texts/Navigation.en';

interface NavigationProps {
	setModal?: (boolean) => void;
}

const Navigation: React.FC<NavigationProps> = ({ setModal }) => {
	return (
		<Nav>
			{headerNavigationTexts.map(({ link, title }) => (
				<NavLink onClick={() => setModal && setModal(false)} href={link} key={title}>
					{title}
				</NavLink>
			))}
		</Nav>
	);
};

export default Navigation;
