import React from 'react';
import { HeaderWrapper, HeaderContent, HeaderNavWrapper, CustomizeButton } from './Header.dump';
import { Wrapper } from 'src/components/GlobalUI';
import Navigation from './Nav';
import MobileNav from './MobileNav';
import { ReactComponent as LogoIcon } from 'icons/logo-VM.svg';

const Header: React.FC = () => {
	return (
		<HeaderWrapper>
			<Wrapper>
				<HeaderContent>
					<a href="#about">
						<LogoIcon />
					</a>
					<HeaderNavWrapper>
						<Navigation />
					</HeaderNavWrapper>
					<CustomizeButton href="#customize">Customize</CustomizeButton>
					<MobileNav />
				</HeaderContent>
			</Wrapper>
		</HeaderWrapper>
	);
};

export default Header;
