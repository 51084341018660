import styled from 'styled-components';
import { WIDTH_BREAKPOINT_MD } from '@virgilsecurity/virgil-ui/src/lib/constants';
import { titillium17 } from '@virgilsecurity/virgil-ui/src/lib/fonts';

export const Nav = styled.nav`
	padding: 0 30px;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		display: flex;
		flex-direction: column;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		margin-left: 100px;
	}
`;

export const NavLink = styled.a`
	${titillium17}
	color: white;
	padding: 10px 22px;
	text-decoration: none;
	transition: all 0.2s;

	&:hover {
		color: #d32f2f;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		color: #bcbdc6;
		margin: 10px 0;

		&:hover,
		&.active {
			color: white;
		}
	}
`;

export const MobileNavWrapper = styled.div`
	display: flex;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		display: none;
	}
`;

export const MobileNavOverlay = styled.div<{ isOpen: boolean }>`
	background: #000000;
	bottom: 0;
	height: ${props => (props.isOpen ? '100%' : '0')};
	left: 0;
	opacity: ${props => (props.isOpen ? '0.3' : '0')};
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: visibility 0.5s, opacity 0.5s;
	visibility: ${props => (props.isOpen ? 'initial' : 'hidden')};
	z-index: 20;
`;

export const MobileNavContainer = styled.div<{ isOpen: boolean }>`
	background: #21232f;
	border-radius: 6px;
	height: ${props => (props.isOpen ? 'calc(100% - 30px)' : '0')};
	opacity: ${props => (props.isOpen ? '1' : '0')};
	overflow-y: scroll;
	position: fixed;
	right: 15px;
	top: 15px;
	transition: visibility 0.5s, opacity 0.5s;
	visibility: ${props => (props.isOpen ? 'initial' : 'hidden')};
	width: calc(100% - 30px);
	z-index: 30;
`;

export const MobileNavContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const CustomizeButton = styled(NavLink)`
	padding: 0;
`;

export const CustomizeButtonContainer = styled.div`
	border-top: 1px solid #d32f2f;
	margin: 15px 50px;
	padding: 30px 0 20px;
	text-decoration: none;
`;

export const OpenButton = styled.button`
	background: none;
	border: 0;
	cursor: pointer;
`;

export const CloseButton = styled.button`
	align-self: flex-end;
	background: none;
	border: 0;
	cursor: pointer;
	margin: 20px;
`;
