import React from 'react';
import { ReactComponent as FacebookIcon } from 'images/footer/icon-facebook.svg';
import { ReactComponent as TwitterIcon } from 'images/footer/icon-twitter.svg';
import { ReactComponent as LinkedInIcon } from 'images/footer/icon-linkedin.svg';
import { ReactComponent as MediumIcon } from 'images/footer/icon-medium.svg';

export const headerNavigationTexts = [
	{
		title: 'Features',
		link: '#features',
	},
	{
		title: 'Security',
		link: '#security',
	},
	{
		title: 'Pricing',
		link: '#pricing',
	},
	{
		title: 'Contact Us',
		link: 'mailto:support@virgilsecurity.com?subject=A question about Virgil Messenger',
	},
];

export const footerNavigationTexts = {
	links: [
		{
			title: 'Terms of Service',
			link: 'https://virgilsecurity.com/terms-of-service/',
		},
		{
			title: 'Privacy Policy',
			link: 'https://virgilsecurity.com/privacy-policy/',
		},
		{
			title: 'Contact Us',
			link: 'mailto:support@virgilsecurity.com?subject=A question about Virgil Messenger',
		},
	],
	social: [
		{
			Icon: FacebookIcon,
			link: 'https://www.facebook.com/VirgilSec',
		},
		{
			Icon: TwitterIcon,
			link: 'https://twitter.com/VirgilSecurity',
		},
		{
			Icon: LinkedInIcon,
			link: 'https://www.linkedin.com/company/Virgil-Security-inc-',
		},
		{
			Icon: MediumIcon,
			link: 'https://medium.com/@VirgilSecurity',
		},
	],
};

export const Copyright: React.FC = () => (
	<>
		© Copyright 2020 <a href="https://virgilsecurity.com/">Virgil Security</a>, Inc.
	</>
);
