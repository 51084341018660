import styled from 'styled-components';

export const LayoutContent = styled.div<{ background?: string }>`
	background: ${({ background }) => background || '#fff'};
	color: white;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	position: relative;
`;
