import styled from 'styled-components';
import {
	WIDTH_BREAKPOINT_LG,
	WIDTH_BREAKPOINT_MD,
} from '@virgilsecurity/virgil-ui/src/lib/constants';

export const Wrapper = styled.div`
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		max-width: 960px;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_LG}) {
		max-width: 1200px;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 20px;
	}
`;

export const Section = styled.section<{ background?: string }>`
	background: ${({ background }) => background};
	display: flex;
`;
